import React, { useState } from 'react'
import { CheckboxCustomEvent, IonButton, IonCheckbox, IonItem, IonLabel, IonList } from '@ionic/react'
import AgreementContent from '../../../components/AgreementContent'
import { useTranslation } from 'react-i18next'
import { getAgreementLabels } from '../../../constants/defaults'
import { AgreementState, AgreementType } from '../../../types/types'

interface AgreementsFormProps {
  nextStep: () => void
  prevStep: () => void
  agreementsAccepted: AgreementState
  setAgreementsAccepted: React.Dispatch<React.SetStateAction<AgreementState>>
}

const AgreementsForm: React.FC<AgreementsFormProps> = ({
  nextStep,
  prevStep,
  agreementsAccepted,
  setAgreementsAccepted,
}) => {
  const { t } = useTranslation()
  const [agreementsError, setAgreementsError] = useState<AgreementState>({
    gdpr: false,
    eula: false,
    volunteer: false,
  })
  const [agreementsLoading, setAgreementsLoading] = useState<AgreementState>({
    gdpr: true,
    eula: true,
    volunteer: true,
  })

  const agreementLabels = getAgreementLabels(t)

  const handleCheckboxChange = (agreement: AgreementType) => (e: CheckboxCustomEvent) => {
    setAgreementsAccepted((prev) => ({
      ...prev,
      [agreement]: e.detail.checked,
    }))
  }

  const handleLoadComplete = (agreementType: AgreementType, hasError: boolean) => {
    setAgreementsLoading((prev) => ({
      ...prev,
      [agreementType]: false,
    }))
    setAgreementsError((prev) => ({
      ...prev,
      [agreementType]: hasError,
    }))
  }

  const allLoaded = Object.values(agreementsLoading).every((loading) => !loading)
  const anyAgreementError = Object.values(agreementsError).some((error) => error)
  const allAccepted = Object.values(agreementsAccepted).every(Boolean)

  const disableNextButton = !allAccepted || anyAgreementError || !allLoaded

  return (
    <>
      <h1 className="ion-padding-vertical">{`${t('signup.readAgreements')} - ${t('signup.step2')}`}</h1>
      {(['gdpr', 'eula', 'volunteer'] as AgreementType[]).map((agreementType) => (
        <AgreementContent key={agreementType} agreementType={agreementType} onLoadComplete={handleLoadComplete} />
      ))}

      <IonList lines="none" className="ion-padding">
        {(['gdpr', 'eula', 'volunteer'] as AgreementType[]).map((agreementType) => (
          <IonItem key={agreementType}>
            <IonCheckbox
              slot="start"
              onIonChange={handleCheckboxChange(agreementType)}
              checked={agreementsAccepted[agreementType]}
              disabled={agreementsError[agreementType] || agreementsLoading[agreementType]}
            />
            <IonLabel>{t('agreement.accept', { agreement: agreementLabels[agreementType].accept })}</IonLabel>
          </IonItem>
        ))}
        <IonItem className="ion-margin-top">
          <IonButton size="default" onClick={prevStep} fill="outline">
            {t('btn.back')}
          </IonButton>
          <IonButton size="default" onClick={nextStep} disabled={disableNextButton}>
            {t('btn.next')}
          </IonButton>
        </IonItem>
      </IonList>
    </>
  )
}

export default AgreementsForm
